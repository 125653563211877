.xvia-title-section__container {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

.xvia-title-section__block_title {
    display: flex;
    align-items: center;
  
    > svg {
      margin: 0 24px;
    }
}

.xvia-title-section__bar {
    background-color: #ff891b;
    height: 70px;
    width: 6px;
    border-radius: 10px;
}

.xvia-title-section__text_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    > p {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 1px;
      color: #202020;
      font-family: 'Roboto Serif', serif;
      margin: 0;
    }
  
    > span {
      font-size: 20px;
      line-height: 26px;
      color: #7b7b7b;
      letter-spacing: 1px;
    }
}

.xvia-title-section__block_button {
    display: flex;
    align-items: center;
}
  
.xvia-title-section__block_button svg {
    margin: 0 5px 0 0 !important;
}

@media (max-width: 800px) {

    .xvia-title-section__block_title {
        display: flex;
        flex-direction: column-reverse;
    
        
        > svg {
          display: none;
        }
      }
    
      .xvia-title-section__bar {
        height: 5px;
        width: 100%;
        margin: 10px;
      }


    .xvia-title-section__container {
        flex-direction: column;
    }

    .xvia-title-section__block_button {
        justify-content: center;
        margin: 20px 0 0 0;
    }
}